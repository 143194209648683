/** @format */

import { Options } from '@contentful/rich-text-react-renderer';
import { BLOCKS, Document } from '@contentful/rich-text-types';
import { Entry } from 'contentful';
import { FunctionComponent as FC, useContext } from 'react';
import { ThemeContext, styled } from 'styled-components';
import { CenterBigTitle } from '../../types/page';
import { Container, ContainerWrapper } from '../ui/Layout';
import { RichText } from '../ui/RichText';
import { H3, Kicker } from '../ui/Typography';
import { TextCard } from './TextCard';

export const TextCardsSection: FC<Props> = ({ title, cards }) => {
  const theme = useContext(ThemeContext);
  return (
    <ContainerWrapper as="section" $color={theme?.color.white}>
      <StyledContainer>
        <RichText text={title} customOptions={options} />
        <Grid>
          {cards.map(({ fields }, i) => (
            <TextCard key={`textcard-${i}`} card={fields} />
          ))}
        </Grid>
      </StyledContainer>
    </ContainerWrapper>
  );
};

type Props = {
  title: Document;
  cards: Entry<CenterBigTitle['fields']>[];
};

const StyledContainer = styled(Container)`
  text-align: center;

  h2 {
    color: ${({ theme }) => theme.color.teal.light};
  }
  p {
    padding-block: 0;
    color: ${({ theme }) => theme.color.teal.dark};
  }
`;

const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: center;
  margin-inline: auto;
  margin-top: clamp(2.3rem, 1.65rem + 2.8889vw, 4.25rem);
  row-gap: 0.94rem;
  column-gap: 2.25rem;
`;

const options: Options = {
  renderNode: {
    [BLOCKS.HEADING_2]: (node, children) => <H3 as="h2">{children}</H3>,
    [BLOCKS.PARAGRAPH]: (node, children) => {
      // to avoid rendering empty paragraphs
      if (Array.isArray(children) && children.length === 1 && children[0] === '') return;
      return <Kicker>{children}</Kicker>;
    },
  },
};
