/** @format */

import { Options } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import { FunctionComponent as FC, useCallback, useContext, useMemo } from 'react';
import { ThemeContext, styled } from 'styled-components';
import { CenterBigTitle } from '../../types/page';
import { FindColor } from '../../utils/findThemeColor';
import { RichText } from '../ui/RichText';
import { H4, P } from '../ui/Typography';

export const TextCard: FC<Props> = ({ card }) => {
  const theme = useContext(ThemeContext);
  const getColor = useCallback((color: string) => {
    switch (color) {
      case 'Teal Light':
      case 'Teal Dark':
        return color.replace(' ', '.').toLowerCase();
      default:
        return color.toLowerCase();
    }
  }, []);

  const textColor = useMemo(() => FindColor(getColor(card.textColor), theme?.color), [card]);
  const background = useMemo(() => FindColor(getColor(card.background), theme?.color), [card]);

  return (
    <Card $color={textColor} $background={background}>
      <RichText text={card.body} customOptions={options} />
    </Card>
  );
};

type Props = {
  card: CenterBigTitle['fields'];
};

type StyleProps = {
  $color: string;
  $background: string;
};

const Card = styled.div<StyleProps>`
  background: ${({ $background }) => $background};

  padding-block: clamp(1.88rem, 1.39rem + 2.1778vw, 3.35rem);
  padding-inline: clamp(1.94rem, 1.62rem + 0.3556vw, 1.7rem);
  border-radius: 1.56rem;

  max-width: 25rem;
  min-height: clamp(11rem, 8.3333rem + 11.8519vw, 19rem);

  h3,
  p {
    color: ${({ $color }) => $color} !important;
  }

  p {
    margin-top: clamp(0.94rem, 0.7533rem + 0.8296vw, 1.5rem);
  }
`;

const options: Options = {
  renderNode: {
    [BLOCKS.HEADING_3]: (node, children) => <H4 as="h3">{children}</H4>,
    [BLOCKS.PARAGRAPH]: (node, children) => {
      // to avoid rendering empty paragraphs
      if (Array.isArray(children) && children.length === 1 && children[0] === '') return;
      return <P>{children}</P>;
    },
  },
};
